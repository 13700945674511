<template>
    <div id="nyertesek" class="winners block--big bg-primary">
        
            <h2 class="text-center mt-0 text-white mb-5">
                Nyertesek
            </h2>
            <div class="container-xl">
                <div class="row">                
                    <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center order-1">
                        <p class="m-0 winners__text text-white">                        
                            <b>Kedves Játékosunk!</b><br><br>
                            A napi nyeremények* nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn) kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.
                            <br><br>
                            *A nyerő időpontok 8 és 22 óra között kerülnek kisorsolásra.
                             
                        </p>
                       <!-- <div class="text-white mt-5 custom-winning-box">
                            <span class="fw-bold text-secondary h5 d-block">Sorsolás</span><br>
                            <p>A fődíj, valamint extra nyeremény sorsolására <span class="text-secondary">2024. 07. 22-én 14:00 órakor</span> kerül sor.</p>
                            <p class="fw-bold">A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)</p>
                        </div>-->
                    </div>                                                
                </div>
            </div>
            <div class="winners__winning-wrap">
                <div class="container-xl py-0">
                    <div class="row">
                        <div class="col-12 col-lg-8 offset-lg-2 text-center">
                            <img src="@/assets/imgs/hero-winnings.png" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <!-- NYertesek -->
            <div class="container-xl block--big py-5 mt-5">
                <div class="row">
                    <div class="col-12 col-lg-4 text-lg-end text-center mb-3" v-if="winnerState === 'napi'">
                        <Datepicker :inline="true" :disabled-date="disabledDates" v-model="dateValue" value-type="format" format="YYYY-MM-DD"></Datepicker>
                    </div>
                    <div class="col-12" :class="winnerState !== 'napi'? '':'col-lg-8'">
                        <!-- TABLE -->
                        <div class="winners__border mb-5">
                            <div class="winners__table-wrap winners__table-wrap--heading">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                    <thead>
                                        <tr class="winners-top font-primary" style="border-bottom: 1px solid #ffffff;">
                                            <th class="w-33 text-yellow">Nyertes neve</th>
                                            <th class="w-33 text-yellow">Város</th>       
                                            <th class="w-33 text-yellow">Nyeremény</th>                      
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="winners__table-wrap winners__table-wrap--bg text-dark">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                    <tbody v-if="filteredWinners && filteredWinners.length">                          
                                        <tr class="winners-list fs-14 text-white" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                            <td class="winners-id fw-700 text-white w-33 p-3">
                                                <div>{{ winner.name }}</div>                                     
                                            </td>                          
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span>{{winner.field_1}}</span>
                                            </td>
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span v-if="winner.type == 'napi'">10.000 Ft Auchan ajándékkártyán</span>
                                            </td>
                                        </tr>                            
                                    </tbody>
                                    <tbody v-else>
                                        <!-- v-if="loginShow" -->
                                        <tr>
                                            <td class="no-winner text-white text-center py-3" colspan="2">
                                                Hamarosan kiderül, kik lettek a nyertesek
                                            </td>                                
                                        </tr>
                                        <!--<tr v-else>
                                            <td class="no-winner text-center py-3" colspan="2">
                                                A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                            </td>
                                        </tr>-->
                                    </tbody>
                                </table>                        
                            </div>

                            
                        </div>

                        <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                                <button target="_blank" type="button" class="btn btn-primary" v-on:click="moreWinners(true)">Mutasd a további nyerteseket</button>
                        </div>
                    </div>           
                    
                </div>  
            </div> 
            <!-- <div class="col-12">
                <p class="text-center text-white fs-14">A nyeremények képe csak illusztráció!</p>
            </div>-->
        
    </div>
</template>


<script>
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    components: {
        Datepicker
    },
    data () {
        return {
            winnersAll:[],
            winnerState:'napi',
            needMoreBtn: false,
            needMoreWinners: false,
            dateValue: "",
            refreshKey: 0
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            let _this = this
            this.refreshKey;

            if(this.winnersAll ?? []){                
                
                //result = this.winnersAll.filter(w => w.type === this.winnerState)

                _this.winnersAll.forEach(w => {
                    if (_this.winnerState === 'napi'){
                        if(_this.dateValue === w.winning_time){
                            result.push(w)
                        }
                    }

                })

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    watch:{      
        dateValue(){
            this.refreshKey++;
            console.log(this.dateValue);

        }
    },
    methods: {
        disabledDates: function (date) {
            return date < new Date(2024, 9, 3) || date > new Date(2024, 9, 30);
        },
        setDailyWinners(date){  
            console.log(date);

        },
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
      /*  selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }*/
    },
    created(){

        this.dateValue = new Date();

        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
         // this.winnerState = "napi";
          //this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })

      //  this.setWinnerState('napi')
    }
    
}
</script>