<template>
    <div class="position-relative">
        <ValidationProvider 
            class="mb-4"
            :rules="rules" 
            :name="$attrs.name" 
            :vid="name" 
            v-slot="{ valid, errors }"
            tag="div"
        >
            
            <label 
                :for="name"
                class="form-label"
            >   
            {{ label }}
                <span 
                    v-if="rules" 
                    class="label-red"
                >
                    *
                </span>
            </label>
            <textarea 
                class="form-control"
                :id="name"
                :name="name"
                :value="value"
                :state="errors[0] ? false : (valid ? true : null)"
                @input="$emit('input', $event.target.value)"
                :placeholder="placeholder"
            />
            <!-- error heandling -->            
            <span class="input-error" v-if=" errors[0] " :class="{'active-error': errors[0]}">{{ errors[0] }}</span>
        </ValidationProvider>
    </div>    
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
    name: 'CustomInput',
    components: {
            ValidationProvider
    },
    props: {
        label: {
            required: true,
            type: String
        },
        value: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ""
        },
        placeholder:{
            required:false,
            type: String
        }
    }
}
</script>

